<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Layout from "../../layouts/main.vue";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import PageHeader from "@/components/page-header";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {},
      filiere: {},
      students: {},
      classes: [],
      attenances: [],
      attenanceDetails: [],
      studentOptions: [],
      facultiesOptions: [],
      admitions: [],
      filteredAdmitions: [],
      currentTime: "",
      submited: false,
      title: "Gestion Presences",
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      items: [
        {
          text: "Etudiants",
          href: "/new-student",
        },
        {
          text: "Presences",
          active: true,
        },
      ],
    };
  },
  validations: {
    data: {
      course: {
        required: helpers.withMessage(
          "Veuillez selectionnez un cours",
          required
        ),
      },
      date: {
        required: helpers.withMessage(
          "Veuillez selectionnez une date",
          required
        ),
      },
      heure: {
        required: helpers.withMessage(
          "Veuillez selectionnez une heure",
          required
        ),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    // flatPickr,
  },
  mounted() {
    this.decryptFiliere();
    this.getAttendencesList();
  },

  methods: {
    submit() {
      this.v$.data.$touch();
      let studentData = this.filteredAdmitions.map((filStudent) => {
        return {
          student: filStudent.data.student_id,
          checkboxValue: filStudent.checkboxValue,
        };
      });
      const donne = new FormData();
      donne.append("students", JSON.stringify(studentData));
      this.$store
        .dispatch("postRequest", {
          route: "/api/uploadAttendances",
          data: donne,
        })
        .then(
          function (response) {
            Swal.fire(
              response.data.message,
              "La liste de presence a été enregistré!",
              "success"
            );
          },
          function (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Il y a eu une erreur!",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        );
    },

    getAttenance(details) {
      this.attenanceDetails = details.attendance_details.map((elmt) => ({
        presence: elmt.presence === 1? true : false,
        matricule: elmt.students?.matricule,
        student: elmt.students?.nom + " " + elmt.students?.prenom,
      }));
    },

    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");
      if (!encryptedMessage1) {
        console.error('Aucune donnée trouvée pour "dataF" dans localStorage.');
        return;
      }

      const encryptionKey1 = "Slim#9065";

      try {
        const decrypted1 = CryptoJS.AES.decrypt(
          encryptedMessage1,
          encryptionKey1
        );
        const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);

        if (!decryptedMessage1) {
          console.error(
            "Erreur lors du décryptage : le message décrypté est vide ou invalide."
          );
          return;
        }

        this.filiere = JSON.parse(decryptedMessage1);
        this.data.filiere_id = this.filiere.id;
        this.data.annee_scolaire = localStorage.getItem("selectedYear");
      } catch (error) {
        console.error("Erreur lors du décryptage des données :", error);
      }
    },
    getAttendencesList() {
      this.data.school_year = localStorage.getItem("selectedYear");
      this.data.filiere_id = this.filiere.id;
      this.$store
        .dispatch("getRequest", {
          route: `api/course/attendances/${this.data.filiere_id}`,
          data: this.data,
        })
        .then((response) => {
          this.attenances = response.data
            .map((attenance) => {
              const classSession = attenance.class_session_attendances?.[0];

              if (classSession) {
                return {
                  matiere: classSession.session.matiere.nom_cours,
                  date: classSession.date,
                  heure: classSession.heure,
                  details: classSession,
                };
              }

              return null;
            })
            .filter((attenance) => attenance !== null);
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" placeholder="Search..." />
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead>
                <tr>
                  <th scope="col">Matière</th>

                  <th scope="col">Date</th>

                  <th scope="col">Heure</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="attenance in attenances" :key="attenance.id">
                  <td>{{ attenance.matiere }}</td>
                  <td>{{ attenance.date }}</td>
                  <td>{{ attenance.heure }}</td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        @click="getAttenance(attenance.details)"
                        data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-lg"
                        href="javascript:void(0);"
                        class="btn btn-success fs-20"
                      >
                        <i class="ri-eye-line"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form>
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    presences des élèves
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <table class="table align-middle table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">matricule</th>

                        <th scope="col">Etudiant</th>

                        <th scope="col">Absence</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="attenance in attenanceDetails" :key="attenance.id">
                        <th scope="row">{{ attenance.matricule  }}</th>

                        <td>{{attenance.student  }}</td>

                        <td>
                          <!-- <div class="checkbox checkbox-danger error-checkbox"> -->
                            <input
                              type="checkbox"
                              v-model="attenance.presence"
                            />
                          <!-- </div> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>